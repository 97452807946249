<template>
  <div class="home">
    <el-row class="row-bg" justify="space-around">
      <el-col :span="6">
        <el-upload
          v-model:file-list="fileList"
          drag
          class="upload-demo"
          :on-preview="handlePreview"
          :on-remove="handleRemove"
          :before-remove="beforeRemove"
          :limit="1"
          :on-exceed="handleExceed"
          :auto-upload="false"
        >
          <el-icon class="el-icon--upload"><upload-filled /></el-icon>
          <div class="el-upload__text">
            Drop file here or <em>click to upload</em>
          </div>
          <!-- <template #trigger>
            <el-button type="primary">select file</el-button>
          </template> -->
          <!-- <template #tip>
            <div class="el-upload__tip">
              jpg/png files with a size less than 500KB.
            </div>
          </template> -->
        </el-upload>
      </el-col>
      <el-col :span="16">
        <el-button type="primary" @click="handleUpload" :loading="uploading"
          >点击上传</el-button
        >
        <p v-if="uploading">上传进度： {{ uploadPercent.toFixed(2) }} %</p>

        <el-table :data="tableData" style="width: 100%">
          <el-table-column prop="file_name" label="文件名" width="180" />
          <el-table-column prop="status" label="状态" width="180">
            <template #default="scope">
              {{ statusMap[scope.row.status] || scope.row.status }}
            </template>
          </el-table-column>
          <el-table-column prop="created_at" label="创建时间">
            <template #default="scope">
              {{ moment(scope.row.created_at).format("YYYY-MM-DD hh:mm:ss") }}
            </template>
          </el-table-column>
          <el-table-column prop="created_at" label="下载">
            <template #default="scope">
              <el-space>
                <a
                  v-show="scope.row.processed_url"
                  :href="
                    scope.row.processed_url
                      ?.replace('.mp4', '.srt')
                      .replace('.MP4', '.srt')
                  "
                  >字幕文件</a
                >
                <a
                  v-show="scope.row.processed_url"
                  :href="scope.row.processed_url?.replace('.MP4', '.mp4')"
                  >带字幕视频</a
                >
              </el-space>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
  </div>
</template>

<script setup>
// @ is an alias to /src
import OSS from "ali-oss";
import axios from "axios";
import moment from "moment";

import { ref } from "vue";
import { ElMessage, ElMessageBox, ElUpload } from "element-plus";
import { UploadFilled } from "@element-plus/icons-vue";

// const baseServer = "http://localhost:8000";
const baseServer = "https://video-process.windymuse.com.cn";
const fileList = ref([]);
const uploading = ref(false);
const uploadPercent = ref(0);

const handleRemove = (file, uploadFiles) => {
  console.log(file, uploadFiles);
};

const handlePreview = (uploadFile) => {
  console.log(uploadFile);
};

const handleExceed = (files, uploadFiles) => {
  fileList.value = files;
  // ElMessage.warning(
  //   `The limit is 3, you selected ${files.length} files this time, add up to ${
  //     files.length + uploadFiles.length
  //   } totally`
  // );
};

const beforeRemove = (uploadFile, uploadFiles) => {
  return ElMessageBox.confirm(
    `Cancel the transfer of ${uploadFile.name} ?`
  ).then(
    () => true,
    () => false
  );
};

const getSts = async () => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: baseServer + "/video/api/get-sts/",
    headers: {
      Authorization: "Token 06bd2dae2ba501a02d9e1eaa24e9f9e01bea37f2",
    },
  };

  let response = await axios.request(config);
  console.log("res", JSON.stringify(response.data));
  return response.data;
};

const handleUpload = async () => {
  if (fileList.value.length == 0) {
    ElMessage.warning(`请选择要上传的mp4文件`);
    return;
  }
  let file = fileList.value[0];
  // 如果不是mp4文件，则不上传
  if (file.name.toLocaleLowerCase().indexOf(".mp4") < 0) {
    ElMessage.warning(`只支持mp4文件上传`);
    return;
  }
  console.log("start handle upload", file, fileList);
  let res = await getSts();
  console.log("get sts res", res);
  let client = new OSS({
    region: "oss-cn-zhangjiakou",
    accessKeyId: res.AccessKeyId,
    accessKeySecret: res.AccessKeySecret,
    stsToken: res.SecurityToken,
    bucket: "maidong-video-process",
  });
  try {
    // 文件路径和名称，通常是上传后在OSS上的路径
    const key = "source/" + file.name;

    // 调用ossClient的put方法上传
    uploading.value = true;
    // let result = await client.put(key, file.raw);

    const options = {
      // 获取分片上传进度、断点和返回值。
      progress: (p, cpt, res) => {
        console.log(p);
        uploadPercent.value = p * 100;
      },
      // 设置并发上传的分片数量。
      parallel: 4,
      // 设置分片大小。默认值为1 MB，最小值为100 KB。
      partSize: 1024 * 1024,
      // headers,
      // 自定义元数据，通过HeadObject接口可以获取Object的元数据。
      meta: { year: 2020, people: "test" },
      mime: "text/plain",
    };
    // 异步上传文件
    let result = await client.multipartUpload(key, file.raw, {
      ...options,
      // 设置上传回调。
      // 如果不涉及回调服务器，请删除callback相关设置。
      // callback: {
      //   // 设置回调请求的服务器地址。
      //   url: "http://examplebucket.aliyuncs.com:23450",
      //   // 设置回调请求消息头中Host的值，即您的服务器配置Host的值。
      //   host: "yourHost",
      //   /* eslint no-template-curly-in-string: [0] */
      //   // 设置发起回调时请求body的值。
      //   body: "bucket=${bucket}&object=${object}&var1=${x:var1}",
      //   // 设置发起回调请求的Content-Type。
      //   contentType: "application/x-www-form-urlencoded",
      //   customValue: {
      //     // 设置发起回调请求的自定义参数。
      //     var1: "value1",
      //     var2: "value2",
      //   },
      // },
    });
    console.log("result", result);
    if (result.res.status === 200) {
      // 上传成功，可以将文件路径发送到后端
      await sendFileInfoToServer(result);
    }
  } catch (e) {
    console.error(e);
    // 错误处理
  }
};
const sendFileInfoToServer = async (uploadResult) => {
  // 获取文件在OSS上的url
  const fileUrl = uploadResult.res.requestUrls[0]
    .replace("http://", "https://")
    .split("?")[0];
  // 发送到后端的数据
  const data = {
    filename: uploadResult.name,
    url: fileUrl,
    // ...其他可能的文件信息
  };
  console.log("sendFileInfoToServer", fileUrl, data);

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: baseServer + "/video/api/video-process/",
    headers: {
      Authorization: "Token 06bd2dae2ba501a02d9e1eaa24e9f9e01bea37f2",
    },
    data: {
      user: 1,
      file_name: uploadResult.name.split("/")[1],
      original_url: fileUrl,
      estimated_duration: 100,
    },
  };

  let response = await axios.request(config);
  // console.log("res", JSON.stringify(response.data));
  uploading.value = false;
  // // 使用axios发送到你的后端
  // await axios.post("your-backend-endpoint", data);
};

const statusMap = {
  PE: "等待中",
  PR: "处理中",
  CO: "已完成",
  FA: "失败",
  NE: "不存在",
};
</script>

<script>
export default {
  name: "HomeView",
  components: {
    ElUpload,
  },
  data: function () {
    return {
      baseServer: "https://video-process.windymuse.com.cn",
      tableData: [],
      timer: null,
    };
  },
  mounted() {
    this.timer = setInterval(() => {
      this.getPEVideos();
    }, 3000);
  },
  unmounted() {
    clearInterval(this.timer);
  },
  methods: {
    async getPEVideos() {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: this.baseServer + "/video/api/video-process/",
        headers: {
          Authorization: "Token 06bd2dae2ba501a02d9e1eaa24e9f9e01bea37f2",
        },
      };

      let response = await axios.request(config);
      // console.log("res", response.data.results);
      this.tableData = response.data.results;
    },
  },
};
</script>
